<template>
  <v-dialog
    v-model="isShowModal"
    max-width="1024px"
    persistent>
    <v-card>
      <material-card
        :title="$t('evoucher.confirmUsedVoucher')"
        tile
        color="green"
        full-width
      >
        <v-container
          grid-list-xl
          fluid
          style="padding: 0;">
          <v-form
            ref="form"
            lazy-validation>
            <v-layout
              layout
              wrap>
              <v-flex
                xs12
                sm3
                md3>
                <v-text-field
                  v-model="usedInfo.orderCode"
                  :rules="[ruleRequiredValue]"
                  :label="$t('evoucher.referenceCode')"
                  :name="$t('evoucher.referenceCode')"
                  class="required"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm3
                md3>
                <v-text-field
                  v-model="usedInfo.customerName"
                  :label="$t('evoucher.customerUsed')"
                  :name="$t('evoucher.customerUsed')"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm3
                md3>
                <v-text-field
                  v-model="usedInfo.customerEmail"
                  :label="$t('customer.emailCustomer')"
                  :name="$t('customer.emailCustomer')"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm3
                md3>
                <v-text-field
                  v-model="usedInfo.customerPhone"
                  :label="$t('customer.phone')"
                  :name="$t('customer.phone')"
                  type="text"
                />
              </v-flex>
              <v-flex
                v-if="forConasi"
                xs12
                sm3
                md3>
                <v-menu
                  ref="menu"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startDateFormatted"
                      :label="$t('evoucher.arrivalDate')"
                      persistent-hint
                      @blur="date = parseDate(startDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    :min="minStartDate"
                    no-title
                    @input="menuStartDate = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                v-if="forConasi"
                xs12
                sm3
                md3>
                <v-menu
                  ref="menu"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endDateFormatted"
                      :label="$t('evoucher.departureDate')"
                      persistent-hint
                      @blur="date = parseDate(endDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    :min="minEndDate"
                    no-title
                    @input="menuEndDate = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                v-if="forConasi"
                xs12
                sm6
                md6>
                <v-textarea
                  v-model="usedInfo.note"
                  :label="$t('common.note')"
                  :name="$t('common.note')"
                  type="text"
                  rows="1"
                />
              </v-flex>
            </v-layout>
            <v-layout
              layout
              wrap>
              <v-flex
                xs12
                sm12
                md12>
                <v-btn
                  color="primary darken-1"
                  dark
                  style="float: right;"
                  @click="onAddUsingVoucher"
                >
                  <v-icon
                    size="15"
                    class="mr-2">mdi-tag-plus</v-icon>
                  <span style="text-transform: none;">{{
                    $t('evoucher.addVoucher')
                  }}</span>
                </v-btn>
                <v-btn
                  v-if="forConasi"
                  color="indigo darken-1"
                  dark
                  style="float: right;"
                  @click="onShowAddUsingVoucherByExcel"
                >
                  <v-icon
                    size="15"
                    class="mr-2">mdi-transfer</v-icon>
                  <span style="text-transform: none;">{{
                    $t('evoucher.importVoucher')
                  }}</span>
                </v-btn>
              </v-flex>
              <v-flex
                v-if="eUsingVoucherList.length > 0"
                xs12
                sm12
                md12
                class="py-0">
                {{ $t('evoucher.voucherQuantity') }}: {{ eUsingVoucherList.length }}
              </v-flex>
              <v-flex
                xs12
                sm12
                md12>
                <div @mouseleave="$refs.tblVoucherList.expanded=[]">
                  <v-data-table
                    ref="tblVoucherList"
                    :no-data-text="$t('common.noDataAvailable')"
                    :headers="eUsingVoucherHeaders"
                    :items="eUsingVoucherList"
                    :expand="false"
                    hide-actions
                  >
                    <template
                      slot="headerCell"
                      slot-scope="{ header }">
                      <span
                        v-if="header.value"
                        class="text--darken-3 font-medium"
                        v-text="$t(header.text)"
                      />
                    </template>
                    <template v-slot:items="props">
                      <tr
                        style="position: relative;"
                        @click="props.expanded=!props.expanded"
                        @mouseover="props.expanded=true">
                        <td
                          v-for="(header, index) in eUsingVoucherHeaders"
                          :key="header.value + index">
                          <!-- <div
                            v-if="header.value == 'stt'"
                            class="text-xs-center">
                            {{ index+1 }}
                          </div> -->
                          <div
                            v-if="header.value == 'stt'"
                            class="text-xs-center">
                            {{ props.index + 1 }}
                          </div>
                          <div
                            v-else-if="header.value == 'price'"
                            class="text-xs-center">
                            {{ formatMoney(props.item[header.value]) }} VNĐ
                          </div>
                          <div
                            v-else-if="header.value == 'actions'"
                            class="text-xs-center">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  class="mx-2"
                                  fab
                                  small
                                  color="red"
                                  style="color: #ffffff;"
                                  v-on="on"
                                  @click="onRemoveUsingVoucher(props.item.id)">
                                  <v-icon dark>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('common.delete') }}</span>
                            </v-tooltip>
                          </div>
                          <div
                            v-else
                            class="text-xs-center">{{ props.item[header.value] }}</div>
                        </td>
                      </tr>
                    </template>
                    <!-- <template v-slot:expand="props">
                      <div
                        class="py-2 mx-3"
                        style="padding-left: 70px"
                        @mouseleave="props.expanded=false">
                        <div style="display: flex; white-space: nowrap;">
                          <action-item
                            v-if="hasAction('view_voucher') || isUsageSite()"
                            first
                            class="text-success"
                            @click="showPreviewVoucher($event, props.item.id)">
                            {{ $t('infoVoucher.voucherInformation') }}
                          </action-item>
                        </div>
                      </div>
                    </template> -->
                  </v-data-table>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-btn
          small
          color="error darken-1"
          @click="isShowModal = false">
          {{ $t("common.close") }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="forConasi"
          :loading="isLoading"
          :disabled="eUsingVoucherList.length==0"
          small
          color="warning darken-1"
          @click="onConfirm($event, true)">
          {{ $t("voucherUsed.lockUsing") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          :disabled="eUsingVoucherList.length==0"
          small
          color="success darken-1"
          @click="onConfirm($event, false)">
          {{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <add-using-voucher-modal
      ref="addVoucherModal"
      @onConfirm="onConfirmAddUsingVoucher"/>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="doConfirmUsingVouchers"
    />
    <useVoucherByExcelModal
      ref="useVoucherByExcelModal"
      @onConfirm="emitVoucherUseByExcel"
    />
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
import ToastType from 'enum/toastType'
import { FOR_CONASI_TARGET } from 'utils/constants'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import dateUtils from 'utils/dateUtils'
import VoucherValue from 'enum/voucherValue'
import AddUsingVoucherModal from './AddUsingVoucherModal.vue'
import ConfirmModal from 'Components/ConfirmModal'
import UseVoucherByExcelModal from './UseVoucherByExcelModal.vue'
import moment from 'moment'
export default {
  components: {
    AddUsingVoucherModal,
    ConfirmModal,
    UseVoucherByExcelModal
  },
  data () {
    return {
      isShowModal: false,
      isLoading: false,
      confirmModalTitle: '',
      VoucherValue: VoucherValue,
      lockUsing: false,
      usedInfo: {
        orderCode: '',
        customerName: '',
        customerPhone: '',
        customerEmail: '',
        note: null
      },
      eUsingVoucherList: [],
      eUsingVoucherHeaders: [
        {
          sortable: false,
          text: 'common.nonumber',
          value: 'stt',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.voucherSerial',
          value: 'voucherSerial',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.voucherName',
          value: 'voucherName',
          align: 'center'
        },
        {
          sortable: false,
          text: 'customer.nameCustomer',
          value: 'customerName',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.issuance.supplier',
          value: 'supplierName',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.issuance.price',
          value: 'price',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.discount',
          value: 'discountValue',
          align: 'center'
        },
        {
          sortable: false,
          text: 'createVoucher.expiredDate',
          value: 'expiredDate',
          align: 'center'
        },
        {
          sortable: false,
          text: 'common.actions',
          value: 'actions',
          align: 'center'
        }
      ],
      forConasi: FOR_CONASI_TARGET,
      //
      startDate: null,
      startDateFormatted: null,
      menuStartDate: false,
      minStartDate: null,
      endDate: null,
      endDateFormatted: null,
      menuEndDate: false,
      minEndDate: null
      //
    }
  },
  watch: {
    startDate (val) {
      this.startDateFormatted = this.formatDate(this.startDate)
      if (this.startDate !== null) {
        if (this.startDate >= this.endDate) {
          this.endDate = moment(this.startDate, 'YYYY-MM-DD')
            .add(1, 'days')
            .format('YYYY-MM-DD')
        }
        this.minEndDate = moment(this.startDate, 'YYYY-MM-DD')
          .add(1, 'days')
          .format('YYYY-MM-DD')
      } else {
        this.minEndDate = null
      }
    },
    endDate (val) {
      this.endDateFormatted = this.formatDate(this.endDate)
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    onRemoveUsingVoucher: function (id) {
      this.eUsingVoucherList = this.eUsingVoucherList.filter(element => {
        return element.id !== id
      })
    },
    onAddUsingVoucher: function () {
      this.$refs.addVoucherModal.onShowModal(this.eUsingVoucherList)
    },
    onConfirmAddUsingVoucher: function (voucher) {
      if (functionUtils.isEmptyString(this.usedInfo.customerEmail)) {
        this.usedInfo.customerEmail = voucher.customerEmail
        this.usedInfo.customerName = voucher.customerName
      }
      this.eUsingVoucherList.push(voucher)
    },
    /**
     * Confirm
     */
    onConfirm: function (event, lockUsing) {
      event.preventDefault()
      if (this.$refs.form.validate()) {
        this.confirmModalTitle = lockUsing ? this.$t('evoucher.confirmLockedUsingMessage') : this.$t('evoucher.confirmUsedDirectlyMessage')
        this.lockUsing = lockUsing
        this.$refs.confirmModal.onShowModal()
      }
    },
    doConfirmUsingVouchers: function () {
      this.isLoading = true
      var voucherIds = []
      for (let i = 0, size = this.eUsingVoucherList.length; i < size; i++) {
        voucherIds.push(this.eUsingVoucherList[i].id)
      }
      let filter = {
        voucherIds: voucherIds,
        lockedUsing: this.lockUsing ? 1 : 0,
        usedInfo: this.usedInfo,
        startDate: !functionUtils.isEmptyString(this.startDate)
          ? dateUtils.formatDate(this.startDate, dateUtils.STATIC_FORMAT_DATE_SQL, 'YYYY-MM-DD HH:mm:ss') : null,
        endDate: !functionUtils.isEmptyString(this.endDate)
          ? dateUtils.formatDate('23:59:59 ' + this.endDate, 'HH:mm:ss YYYY-MM-DD', 'YYYY-MM-DD HH:mm:ss') : null
      }
      this.CONFIRM_USED_MULTIPLE_VOUCHERS(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.isLoading = false
          this.isShowModal = false
        }.bind(this)
      ).catch(
        function (error) {
          this.isLoading = false
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    /**
     * Show modal
     */
    onShowModal: function () {
      this.eUsingVoucherList = []
      this.usedInfo.orderCode = null
      this.usedInfo.customerName = null
      this.usedInfo.customerPhone = null
      this.usedInfo.customerEmail = null
      this.startDate = null
      this.endDate = null
      this.usedInfo.note = null
      this.isShowModal = true
    },
    onShowAddUsingVoucherByExcel: function () {
      this.$refs.useVoucherByExcelModal.onShowModal()
    },
    emitVoucherUseByExcel: function (dataList) {
      dataList.forEach(element => {
        let index = this.eUsingVoucherList.findIndex(e => e.id === element.id)
        if (index === -1) {
          this.eUsingVoucherList.push(element)
        }
      })
      if (functionUtils.isEmptyString(this.usedInfo.customerEmail)) {
        if (dataList.length > 0) {
          this.usedInfo.customerEmail = dataList[0].customerEmail
          this.usedInfo.customerName = dataList[0].customerName
        }
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'CONFIRM_USED_MULTIPLE_VOUCHERS'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
