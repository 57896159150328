<template>
  <v-dialog
    v-model="isShowModal"
    :max-width="'400px'"
    persistent>
    <v-card>
      <material-card
        :title="$t('evoucher.addUsingVoucher')"
        tile
        color="green"
        full-width
      >
        <v-container
          grid-list-xl
          fluid
          style="padding: 0;">
          <v-form
            ref="form"
            lazy-validation>
            <v-layout
              layout
              wrap>
              <v-flex
                xs12
                sm12
                md12>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="activeCode"
                  :label="$t('evoucher.activeCode')"
                  :name="$t('evoucher.activeCode')"
                  type="text"
                  class="required"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="isShowModal = false"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm($event)"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
import ToastType from 'enum/toastType'
import dateUtils from 'utils/dateUtils'
import { FOR_CONASI_TARGET } from 'utils/constants'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
export default {
  data () {
    return {
      isShowModal: false,
      activeCode: null,
      isLoading: false,
      listVoucherAdded: null,
      forConasi: FOR_CONASI_TARGET
    }
  },
  methods: {
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Confirm
     */
    onConfirm: function (event) {
      event.preventDefault()
      if (this.$refs.form.validate()) {
        this.isLoading = true
        let filter = {
          params: {
            activeCode: this.activeCode.trim()
          }
        }
        this.VALIDATE_USING_VOUCHER_STATUS(filter).then(
          function (res) {
            this.isLoading = false
            let data = res.data
            if (this.listVoucherAdded) {
              const found = this.listVoucherAdded.find(element => element.id === data.voucher.id)
              if (found) {
                this.ON_SHOW_TOAST({
                  message: this.$t('evoucher.alreadyAdded'),
                  styleType: ToastType.ERROR
                })
                return
              }
            }
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            let voucher = {
              id: data.voucher.id,
              voucherName: data.voucher.name,
              voucherSerial: data.voucher.serial,
              issuranceType: data.voucher.issurance_type,
              suppilerId: data.voucher.suppiler_id,
              supplierName: data.voucher.suppiler_name,
              customerName: data.voucher.customer_fullname,
              customerEmail: data.voucher.customer_email,
              price: data.voucher.price,
              // discountValue: data.voucher.discount,
              expiredDate: dateUtils.formatBeautyDate(data.voucher.expired_date)
            }
            this.$emit('onConfirm', voucher)
            this.isShowModal = false
          }.bind(this)
        ).catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    /**
     * Show modal
     */
    onShowModal: function (listAdded) {
      this.listVoucherAdded = listAdded
      this.activeCode = null
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'VALIDATE_USING_VOUCHER_STATUS'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
