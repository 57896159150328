<template>
  <v-dialog
    v-model="isShowModal"
    :max-width="MODAL_WIDTH"
    persistent>
    <v-card>
      <material-card
        :title="$t('evoucher.voucherDistribution')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <!-- Download template voucher -->
          <a
            type="button"
            class="v-btn theme--dark warning"
            download
            @click="onDownloadTemplate">
            <v-icon
              class="mr-2"
              color="white">mdi-download</v-icon> <span style="text-transform: none;">{{ $t('common.downloadTemplate') }}</span>
          </a>
          <div
            class="float-right mr-2 pb-4 mt-2"
          >
            <upload-excel
              :on-success="handleSuccess"
              :before-upload="beforeUpload"/>
            <div
              class="mt-4">
              <span>{{ $t('distributeExcelMode.preview') }}</span>
              <div style="max-height: 300px; overflow-y: auto;">
                <table
                  class="mt-2"
                  style="width:100%;">
                  <tr>
                    <th
                      v-for="(header, index) in excelListVoucherIssue.headers"
                      :key="header + index">
                      <span v-if="header === 'errorText'">{{ $t('evoucher.voucherStatus') }}</span>
                      <span v-else>{{ $t('evoucher.' + header) }}</span>
                    </th>
                  </tr>
                  <tr
                    v-for="(result, index) in excelListVoucherIssue.results"
                    :key="result + index">
                    <td
                      v-for="(header, index) in excelListVoucherIssue.headers"
                      :key="header + index">
                      <span
                        v-if="header == 'errorText'"
                        :style="result[header] === null ? 'color: #66bb6a;' : 'color: red;'"
                        class="font-weight-bold">
                        {{ $t(result[header] !== null ? result[header] : 'common.success') }}
                      </span>
                      <span
                        v-else-if="header == 'voucherSerial'">
                        {{ (result[header] && result[header].length) > 0 ? String(result[header]).replace('[', "") : '' }}
                      </span>
                      <!-- <span
                        v-else-if="header == detail"
                        style="white-space: pre-wrap;">
                        {{ result[header] }}
                      </span> -->
                      <span v-else>
                        {{ result[header] }}
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t(!hideBtnConfirm ? "common.close" : "common.cancel") }}
        </v-btn>
        <v-btn
          v-if="hideBtnConfirm"
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm()"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import UploadExcel from 'Components/UploadExcel'
import { mapActions } from 'vuex'
import dateUtils from 'utils/dateUtils'
import stringUtils from 'utils/stringUtils'
// import functionUtils from 'utils/functionUtils'
import XLSX from 'xlsx'
export default {
  components: {
    UploadExcel
  },
  data () {
    return {
      excelListVoucherIssue: {
        results: [],
        headers: []
      },
      domainPath: stringUtils.DOMAIN_BASE_PATH_ADMIN,
      MODAL_WIDTH: '1200px',
      isLoading: false,
      isShowModal: false,
      isForceDistribute: false,
      hideBtnConfirm: false,
      excelData: {
        results: [],
        headers: []
      },
      voucherUseList: []
    }
  },
  methods: {
    /**
     * Confirm emit voucher use by excel mode
     */
    onConfirm () {
      this.$emit('onConfirm', this.voucherUseList)
      this.onCloseModal()
    },
    callApiImportExcel: function () {
      let filter = {
        headers: this.excelData.headers,
        dataImport: this.excelData.results
      }
      this.isLoading = true
      this.USE_VOUCHER_BY_EXCEL_FILE(filter).then(
        function (res) {
          let data = res.data
          this.isLoading = false
          let resultList = data.response_list
          let dataHeader = []
          if (resultList.length > 0) {
            dataHeader = Object.keys(resultList[0])
          }
          this.excelListVoucherIssue.headers = dataHeader
          this.excelListVoucherIssue.results = resultList
          // show btn confirm
          let isShowBtnConfirmUseVoucher = data.isShowBtnConfirmUseVoucher
          this.hideBtnConfirm = isShowBtnConfirmUseVoucher
          // handle voucher use
          let voucherUseData = data.results
          this.voucherUseList = []
          voucherUseData.forEach(e => {
            let voucher = {
              id: e.id,
              voucherName: e.name,
              voucherSerial: e.serial,
              issuranceType: e.issurance_type,
              suppilerId: e.suppiler_id,
              supplierName: e.suppiler_name,
              customerName: e.customer_fullname,
              customerEmail: e.customer_email,
              price: e.price,
              // discountValue: e.discount,
              expiredDate: dateUtils.formatBeautyDate(e.expired_date)
            }
            this.voucherUseList.push(voucher)
          })
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Handle success upload file excel
     */
    handleSuccess: function ({ results, header }) {
      this.excelData.results = results
      this.excelData.headers = header
      this.callApiImportExcel()
    },
    /**
     * Before upload file excel
     */
    beforeUpload: function (file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      return false
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.isLoading = false
      this.isShowModal = false
      this.excelListVoucherIssue.results = []
      this.excelListVoucherIssue.headers = []
      this.hideBtnConfirm = true
      this.voucherUseList = []
      this.$emit('transactionVoucherSuccess')
    },
    /**
     * Show modal
     */
    onShowModal: function () {
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.setDefaultData()
    },
    onDownloadTemplate: async function () {
      let dataList = [
        {
          'Activation Code': '123abc'
        }
      ]
      // export json to Worksheet of Excel
      // only array possible
      var dataSheet = XLSX.utils.json_to_sheet(dataList)

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, dataSheet, 'VoucherUseDataList') // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, 'VoucherUseDataList.xlsx') // name of the file is 'book.xlsx'
    },
    ...mapActions([
      'USE_VOUCHER_BY_EXCEL_FILE'
    ])
  }
}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}
</style>
