<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      v-if="hasAction('view_voucher') || isAccountant() || isAdmin() || isUsageSite()"
      wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  :class="!isSupplier() && forConasi && 'lg5-custom'"
                  xs12
                  md3>
                  <v-text-field
                    v-model="searchData.voucherNameOrCode"
                    :label="$t('evoucher.issuance.issuanceOrSerialVoucher')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  v-if="!isSupplier() && forConasi"
                  :class="!isSupplier() && forConasi && 'lg5-custom'"
                  xs12
                  md3>
                  <v-text-field
                    v-model="searchData.issuanceSupplier"
                    :label="$t('evoucher.issuance.supplier')"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
                <v-flex
                  :class="!isSupplier() && forConasi && 'lg5-custom'"
                  xs12
                  md3>
                  <v-daterange
                    v-model="range"
                    :presets="presets"
                    :input-props="inputProps"
                    :locale="$i18n.locale"
                    :start-label="$t('evoucher.issuance.startDate')"
                    :end-label="$t('evoucher.issuance.endDate')"
                    :menu-props="menuProps"
                    :separator-label="$t('evoucher.to')"
                    style="width: 100%;"
                    display-format="dd/MM/yyyy"
                  />
                </v-flex>
                <v-flex
                  :class="!isSupplier() && forConasi && 'lg5-custom'"
                  xs12
                  md3>
                  <v-select
                    v-model="searchData.voucherStatus"
                    :items="voucherStatusList"
                    :label="$t('user.status')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  :class="!isSupplier() && forConasi && 'lg5-custom'"
                  xs12
                  md3>
                  <v-text-field
                    v-model="searchData.customerInfo"
                    :label="$t('customer.info')"
                    type="text"
                    @keyup.enter="onSearch"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch"
                @keyup.enter="onSearch"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('evoucher.list')"
          color="green"
          flat
          full-width
        >
          <template>
            <div
              v-if="!isAccountant()"
              style="float: right;">
              <v-menu
                offset-y
                transition="slide-y-transition"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="indigo"
                    dark
                    v-on="on">
                    <v-icon class="mr-2">mdi-checkbox-multiple-marked</v-icon>
                    <span style="text-transform: none;">{{ $t('common.bulk') }}</span>
                  </v-btn>
                </template>
                <v-card>
                  <!-- Print selected -->
                  <v-list v-if="forConasi">
                    <v-list-tile @click="onGoScreenPrintVoucher(VoucherPrintType.SELECTED)">
                      <v-list-tile-action>
                        <v-icon color="primary">mdi-printer</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-title>{{ $t('printVoucher.selected') }}</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                  <!-- Print auto -->
                  <!-- <v-list>
                    <v-list-tile @click="onGoScreenPrintVoucher(VoucherPrintType.AUTOMATIC)">
                      <v-list-tile-action>
                        <v-icon color="primary">mdi-tray-full</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-title>{{ $t('printVoucher.auto') }}</v-list-tile-title>
                    </v-list-tile>
                  </v-list> -->
                  <v-list v-if="isSupplier() && !isEntityDisabled()">
                    <v-list-tile @click="onBulkExternMultipleVouchers()">
                      <v-list-tile-action>
                        <v-icon color="primary">mdi-calendar-multiple</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-title>{{ $t('evoucher.issuance.extendSelectedVouchers') }}</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
            <!-- Delivery voucher -->
            <v-btn
              v-if="(selected.length > 0 && (searchData.voucherStatus === null || searchData.voucherStatus === VoucherStatusType.NOT_ASSIGNED_YET))
              && ((hasAction('delivery_voucher') && !isAccountant() && !isEntityDisabled()) || isAdmin())"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAssignVoucher"
            >
              <v-icon class="mr-2">mdi-transfer</v-icon>
              <span style="text-transform: none;">{{ $t("evoucher.voucherDistribution") }}</span>
            </v-btn>
            <v-btn
              v-if="forConasi && !isSupplier() && hasAction('create_request')"
              color="success"
              dark
              style="float: right;"
              @click="requestEVoucherToParent($event)"
            >
              <v-icon
                size="15"
                class="mr-2">mdi-message-bulleted</v-icon>
              <span style="text-transform: none;">{{ $t("issueVoucherRequest.requestTitle") }}</span>
            </v-btn>
            <!-- <v-btn
              v-if="(hasAction('create_voucher') && !isEntityDisabled() || isAdmin()) && !forConasi"
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddNewVoucher"
            >
              <v-icon>mdi-plus</v-icon>
              <span style="text-transform: none;">{{ $t("evoucher.addNewVoucher") }}</span>
            </v-btn> -->
            <v-btn
              v-if="hasAction('confirm_using') && !isAccountant() && (isSupplier() || isUsageSite())"
              color="primary darken-1"
              dark
              style="float: right;"
              @click="onShowConfirmUsedMultipleVouchersModal"
            >
              <v-icon
                size="15"
                class="mr-2">mdi-tag-multiple</v-icon>
              <span style="text-transform: none;">{{ $t('evoucher.confirmMultipleVouchers') }}</span>
            </v-btn>
            <v-btn
              v-if="forConasi && !isAccountant() && ((isSupplier() && hasAction('confirm_using')) || isUsageSite())"
              color="warning darken-1"
              dark
              style="float: right;"
              @click="onShowConfirmUnlockUsingVouchersModal"
            >
              <v-icon
                size="15"
                class="mr-2">mdi-tag-multiple</v-icon>
              <span style="text-transform: none;">{{ $t('evoucher.confirmUnlockUsingVouchers') }}</span>
            </v-btn>
            <!-- <v-btn
              color="success"
              dark
              style="float: right;"
              @click="onGoScreenPrintVoucher($event)"
            >
              <v-icon
                size="15"
                class="mr-2">mdi-printer</v-icon>
              <span style="text-transform: none;">{{ $t('evoucher.printVoucher') }}</span>
            </v-btn> -->
            <v-btn
              v-if="isSupplier() && hasExportExcelPermission() && !isEntityDisabled()"
              color="warning darken-1"
              dark
              style="float: right;"
              @click="onExportVoucherInfoToExcel($event)"
            >
              <v-icon
                size="15"
                class="mr-2">mdi-file-export</v-icon>
              <span style="text-transform: none;">{{ $t('voucherReport.exportExcel') }}</span>
            </v-btn>
          </template>
          <div @mouseleave="$refs.tblVoucherList.expanded=[]">
            <v-data-table
              ref="tblVoucherList"
              :no-data-text="$t('common.noDataAvailable')"
              :headers="voucherHeadersDynamic"
              :items="voucherList"
              :single-select="true"
              v-model="selected"
              :expand="false"
              hide-actions
              select-all
            >
              <template
                slot="headerCell"
                slot-scope="{ header }">
                <span
                  v-if="header.value"
                  class="text--darken-3 font-medium"
                  v-text="$t(header.text)"
                />
              </template>
              <template v-slot:items="props">
                <tr
                  :class="props.item.isLoading ? 'bg-1' : (props.selected ? 'blue--text' : '')"
                  style="position: relative;"
                  @click="props.expanded=!props.expanded"
                  @mouseover="props.expanded=true">
                  <td>
                    <v-checkbox
                      v-model="props.selected"
                      primary
                      hide-details />
                  </td>
                  <td
                    v-for="(header, index) in voucherHeadersDynamic"
                    :key="header.value + index">
                    <span
                      v-if="header.value == 'serial'"
                      class="action-item"
                    >
                      <span v-if="!props.item.isLoading">{{ props.item[header.value] }}</span>
                      <div
                        v-else
                        class="text-xs-center">
                        <v-progress-circular
                          :value="40"
                          indeterminate
                          class="text-xs-center"
                          color="white"
                        />
                      </div>
                    </span>
                    <div
                      v-else-if="header.value == 'workpiece'"
                      style="min-width: 170px;">
                      {{ props.item[header.value] ? props.item[header.value].name : '' }}
                    </div>
                    <span v-else-if="header.value == 'price'">
                      {{ formatMoney(props.item[header.value]) }}
                    </span>
                    <div
                      v-else-if="header.value == 'discountValue'"
                      class="text-xs-center">
                      <div v-if="props.item.discountType == VoucherTransactionValue.PERCENT || props.item.discountType == null">
                        <span style="display: block; white-space: nowrap;">{{ $t('evoucher.discount') }}: {{ props.item[header.value] + '%' }}</span>
                        <span style="white-space: nowrap;">{{ $t('evoucher.priceDiscount') }}: {{ formatMoney(props.item['priceDiscount']) }}</span>
                      </div>
                      <div v-else>
                        <span style="display: block; white-space: nowrap;">{{ $t('evoucher.discount') }}: {{ formatMoney(props.item.discountValue) }}</span>
                      </div>
                    </div>
                    <span v-else-if="header.value == 'pay'">
                      {{ getPayStatusText(props.item[header.value]) }}
                    </span>
                    <span
                      v-else-if="header.value == 'toEntity'"
                      class="action-item"
                      @click="viewDetailEntity($event, props.item[header.value])">
                      {{ props.item[header.value] && props.item[header.value].name }}
                    </span>
                    <span
                      v-else-if="header.value == 'fromEntity'"
                      class="action-item"
                      @click="viewDetailEntity($event, props.item[header.value])">
                      {{ props.item[header.value] && props.item[header.value].name }}
                    </span>
                    <div
                      v-else-if="header.value == 'issuanceDeadlineToCustomers'"
                      class="text-xs-center">
                      <!-- <span style="display: block; white-space: nowrap;">{{ $t('common.from') }} {{ props.item['issueStartDate'] }}</span> -->
                      <span style="white-space: nowrap;">{{ props.item['distributeEndDate'] }}</span>
                    </div>
                    <span
                      v-else-if="header.value == 'status'"
                      :class="'a-state ' + getVoucherStatusColorName(props.item)"
                      style="white-space: nowrap;">
                      {{ $t(getVoucherStatusType(props.item.status)) }}
                    </span>
                    <span
                      v-else-if="header.value == 'issuanceCode' || header.value == 'workpieceCode'"
                      style="white-space: nowrap;">
                      {{ props.item[header.value] }}
                    </span>
                    <div
                      v-else-if="header.value == 'emailCustomer'"
                      style="white-space: nowrap;">
                      <div class="font-weight-bold">{{ getCustomerDisplayName(props.item) }}</div>
                      <div
                        v-if="props.item.emailCustomer"
                        style="white-space: nowrap;">({{ props.item.emailCustomer }})</div>
                    </div>
                    <div
                      v-else-if="header.value == 'deliveryNote'"
                      style="min-width: 150px;">
                      {{ props.item[header.value] }}
                    </div>
                    <span v-else>{{ props.item[header.value] }}</span>
                  </td>
                </tr>
              </template>
              <template v-slot:expand="props">
                <div
                  class="py-2 mx-3"
                  style="padding-left: 70px"
                  @mouseleave="props.expanded=false">
                  <div style="display: flex; white-space: nowrap;">
                    <action-item
                      v-if="hasAction('view_voucher') || isUsageSite() || isAdmin()"
                      first
                      class="text-success"
                      @click="showPreviewVoucher($event, props.item.id)">
                      {{ $t('infoVoucher.voucherInformation') }}
                    </action-item>
                    <!-- <action-item
                      v-if="hasAction('view_voucher') || isUsageSite() || isAdmin()"
                      class="text-success"
                      @click="viewDetailEVoucher($event, props.item.id)">
                      {{ $t('evoucher.viewDetail') }}
                    </action-item> -->
                    <action-item
                      v-if="hasAction('view_history') || isAccountant() || isAdmin() || isUsageSite()"
                      class="blue--text"
                      @click="viewHistoryTransaction($event, props.item.id)">
                      {{ $t('evoucher.historyTransaction') }}
                    </action-item>
                    <action-item
                      v-if="props.item.status == VoucherStatusType.ACTIVATED && !isAccountant() && !isEntityDisabled() && (hasAction('manage_voucher') || isAdmin() || isUsageSite())"
                      class="red--text"
                      @click="enableDisableVoucher($event, props.item, VoucherStatusType.DISABLED)">
                      {{ $t('evoucher.disableVoucher') }}
                    </action-item>
                    <action-item
                      v-if="props.item.status == VoucherStatusType.DISABLED && !isAccountant() && !isEntityDisabled() && (hasAction('manage_voucher') || isUsageSite())"
                      class="red--text"
                      @click="enableDisableVoucher($event, props.item, VoucherStatusType.ACTIVATED)">
                      {{ $t('evoucher.enableVoucher') }}
                    </action-item>
                    <action-item
                      v-if="hasAction('delete_voucher') && !isAccountant() && !isEntityDisabled()"
                      style="color: red;"
                      @click="onDeleteVoucher($event, props.item)">
                      {{ $t('actionPermission.delete_voucher') }}
                    </action-item>
                    <action-item
                      v-if="(props.item.status == VoucherStatusType.DISABLED || props.item.status == VoucherStatusType.ACTIVATED) && isAdmin()"
                      class="deep-purple--text lighten-1"
                      @click="onShowModalTransferVoucherToOtherCustomer($event, props.item)">
                      {{ $t('evoucher.transferVoucher') }}
                    </action-item>
                  </div>
                  <div
                    v-if="props.item.status == VoucherStatusType.ACTIVATED && ((isSupplier() && !isAccountant() && hasAction('confirm_using')) || isUsageSite())"
                    style="display: flex; white-space: nowrap;">
                    <action-item
                      first
                      class="deep-purple--text lighten-1"
                      @click="onShowModalConfirmVoucher($event, props.item, 1)">
                      {{ $t('evoucher.directConfirmation') }}
                    </action-item>
                    <action-item
                      class="indigo--text lighten-3"
                      @click="onShowModalConfirmVoucher($event, props.item, 2)">
                      {{ $t('evoucher.confirmationFromCustomers') }}
                    </action-item>
                    <action-item
                      v-if="isSupplier() && !isEntityDisabled()"
                      class="text-warning lighten-3"
                      @click="onShowModalExternExpiredDays($event, props.item)">
                      {{ $t('evoucher.issuance.extendExpiredDate') }}
                    </action-item>
                  </div>
                </div>
              </template>
            </v-data-table>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="voucherPaginate.totalPage"
      :current-page="voucherPaginate.currentPage"
      :row-per-page="voucherPaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <addEVoucherList
      ref="addEVoucherList"
      @createEVoucherSuccess="onCreateEVoucherSuccess()"
    />
    <assignVoucherModal
      ref="assignVoucherModal"
      @transactionVoucherSuccess="onTransactionVoucherSuccess()"
      @onCreateEVoucher="onShowModalAddListEvoucher"
    />
    <historyTransactionVoucher ref="historyTransactionVoucher" />
    <requestEVoucherToParent ref="requestEVoucherToParent" />
    <entityDetailModal ref="entityDetailModal" />
    <loading-bar :is-loading="isLoading" />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="confirmUsedVoucherFromCustomer"
    />
    <confirmUsedVoucherDirectModal
      ref="confirmUsedVoucherDirectModal"
      @onConfirm="onConfirmUsedDirect" />
    <confirmUsedMultipleVouchersModal
      ref="confirmUsedMultipleVouchersModal" />
    <confirmUnlockUsingVouchersModal
      ref="confirmUnlockUsingVouchersModal" />
    <assignVoucherByExcelModeModal
      ref="assignVoucherByExcelModeModal"
      @transactionVoucherSuccess="onTransactionVoucherSuccess()" />
    <confirmDeleteVoucherModal
      ref="confirmDeleteVoucherModal"
      @onConfirm="onConfirmDeleteVoucher" />
    <add-issuance-and-voucher
      ref="addIssuanceAndVoucher"
      @createEVoucherSuccess="onCreateEVoucherSuccess()"
    />
    <e-voucher-preview-modal ref="voucherPreviewModal" />
    <extend-expired-day-modal
      ref="extendExpiredDayModal"
      @onUpdated="onExtendExpiredDateSuccess()"/>
    <confirm-modal
      ref="enableDisableVoucherModal"
      :title="enableDisableVoucherTitle"
      @onConfirm="confirmEnableDisableVoucher"
    />
    <transferVoucherToOtherCustomerModal
      ref="transferVoucherToOtherCustomerModal"
      @onConfirm="confirmTransferVoucherToOtherCustomer"/>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import VoucherStatusType from 'enum/voucherStatusType'
import AddEVoucherList from './AddEvoucherList'
import CreateVoucherType from 'enum/createVoucherType'
import AssignVoucherModal from './AssignVoucherModal'
import functionUtils from 'utils/functionUtils'
import stringUtils from 'utils/stringUtils'
import HistoryTransactionVoucher from './HistoryTransactionVoucher'
import EntityType from 'enum/entityType'
import ToastType from 'enum/toastType'
import RequestEVoucherToParent from './RequestEVoucherToParent'
import EntityDetailModal from 'Components/EntityDetailModal'
import dateUtils from 'utils/dateUtils'
import VDaterange from 'Components/VDaterange'
import { FOR_CONASI_TARGET, ISO_FORMAT } from 'utils/constants'
import { format, subDays } from 'date-fns'
import ConfirmModal from 'Components/ConfirmModal'
import moment from 'moment'
import XLSX from 'xlsx'
import EntityRoleType from 'enum/entityRoleType'
import ConfirmUsedMultipleVouchersModal from './ConfirmUsedMultipleVouchersModal'
import ConfirmUnlockUsingVouchersModal from './ConfirmUnlockUsingVouchersModal'
import ConfirmUsedVoucherDirectModal from './ConfirmUsedVoucherDirectModal'
import ConfirmDeleteVoucherModal from 'Components/ConfirmDeleteVoucherModal'
import AssignVoucherByExcelModeModal from './AssignVoucherByExcelMode'
import VoucherPrintType from 'enum/voucherPrintType'
import VoucherTransactionValue from 'enum/voucherTransactionValue'
// import EntityStatus from 'enum/entityStatus'
import AddIssuanceAndVoucher from './AddIssuanceAndVoucher'
import EVoucherPreviewModal from 'Views/EVoucher/EVoucherPreviewModal'
import ExtendExpiredDayModal from 'Views/EVoucher/ExtendExpiredDayModal.vue'
import ActionItem from 'Components/ActionItem.vue'
import TransferVoucherToOtherCustomerModal from './TransferVoucherToOtherCustomerModal'

export default {
  components: {
    AddEVoucherList,
    AssignVoucherModal,
    HistoryTransactionVoucher,
    RequestEVoucherToParent,
    EntityDetailModal,
    VDaterange,
    ConfirmModal,
    ConfirmUsedMultipleVouchersModal,
    ConfirmUnlockUsingVouchersModal,
    ConfirmUsedVoucherDirectModal,
    ConfirmDeleteVoucherModal,
    AssignVoucherByExcelModeModal,
    AddIssuanceAndVoucher,
    // EVoucherDetailModal,
    EVoucherPreviewModal,
    ExtendExpiredDayModal,
    ActionItem,
    TransferVoucherToOtherCustomerModal
  },
  data: () => ({
    voucherList: [],
    voucherHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.voucherSerial',
        value: 'serial',
        align: 'center'
      },
      {
        sortable: false,
        text: FOR_CONASI_TARGET ? 'report.quantityVoucherSupplierToAgent.workpiece' : 'evoucher.voucherName',
        value: 'workpiece',
        align: 'center'
      },
      {
        sortable: false,
        text: FOR_CONASI_TARGET ? 'evoucher.issuance.workpiece' : 'evoucher.issuedCode',
        value: FOR_CONASI_TARGET ? 'issuanceCode' : 'workpieceCode',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.supplier',
        value: 'supplierName',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.state',
        value: 'status',
        align: 'center'
      },
      {
        sortable: false,
        text: FOR_CONASI_TARGET ? 'evoucher.issuance.price' : 'evoucher.issuance.pricePercent',
        value: 'price',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.discount',
        value: 'discountValue',
        align: 'center'
      },
      // {
      //   sortable: false,
      //   text: 'evoucher.priceDiscount',
      //   value: 'priceDiscount',
      //   align: 'center'
      // },
      {
        sortable: false,
        text: 'evoucher.pay',
        value: 'pay',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.assignFrom',
        value: 'fromEntity',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.assignTo',
        value: 'toEntity',
        align: 'center'
      },
      // {
      //   sortable: false,
      //   text: 'evoucher.dateUpdated',
      //   value: 'dateUpdated',
      //   align: 'center'
      // },
      // {
      //   sortable: false,
      //   text: 'createVoucher.releaseDeadlineToAgent',
      //   value: 'releaseDeadlineToAgent',
      //   align: 'center'
      // },
      {
        sortable: false,
        text: 'createVoucher.releaseDeadline',
        value: 'issuanceDeadlineToCustomers',
        align: 'center'
      },
      {
        sortable: false,
        text: 'createVoucher.receivedDate',
        value: 'deliveredDate',
        align: 'center'
      },
      {
        sortable: false,
        text: 'createVoucher.expiredDate',
        value: 'expiredDate',
        align: 'center'
      },
      {
        sortable: false,
        text: 'evoucher.customer',
        value: 'emailCustomer',
        align: 'left'
      },
      {
        sortable: false,
        text: 'evoucher.deliveryNote',
        value: 'deliveryNote',
        align: 'left'
      }
    ],
    voucherPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    createVoucherType: CreateVoucherType,
    selected: [],
    searchData: {
      voucherNameOrCode: null,
      voucherStatus: null,
      issuanceSupplier: null,
      voucherPayStatus: null,
      customerInfo: null
    },
    isLoading: false,
    range: {
      start: null,
      end: null
    },
    // voucherPayStatusList: [
    //   { text: '---', value: null },
    //   ...stringUtils.PayStatus
    // ],
    inputProps: { solo: false, style: { width: '270px' } },
    menuProps: { offsetY: true, closeOnContentClick: false },
    presets: [
      {
        label: 'rangeCalendar.today',
        range: [format(new Date(), ISO_FORMAT), format(new Date(), ISO_FORMAT)]
      },
      {
        label: 'rangeCalendar.yesterday',
        range: [
          format(subDays(new Date(), 1), ISO_FORMAT),
          format(subDays(new Date(), 1), ISO_FORMAT)
        ]
      },
      {
        label: 'rangeCalendar.last7Days',
        range: [
          format(subDays(new Date(), 7), ISO_FORMAT),
          format(subDays(new Date(), 1), ISO_FORMAT)
        ]
      },
      {
        label: 'rangeCalendar.lastWeek',
        range: [
          format(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            ),
            ISO_FORMAT
          ),
          format(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            ),
            ISO_FORMAT
          )
        ]
      },
      {
        label: 'rangeCalendar.last30Days',
        range: [
          format(subDays(new Date(), 30), ISO_FORMAT),
          format(subDays(new Date(), 1), ISO_FORMAT)
        ]
      },
      {
        label: 'rangeCalendar.lastMonth',
        range: [
          format(
            new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
            ISO_FORMAT
          ),
          format(
            new Date(new Date().getFullYear(), new Date().getMonth(), 0),
            ISO_FORMAT
          )
        ]
      },
      {
        label: 'rangeCalendar.currentMonth',
        range: [
          format(
            new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            ISO_FORMAT
          ),
          format(
            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
            ISO_FORMAT
          )
        ]
      }
    ],
    confirmModalTitle: '',
    itemId: '',
    itemVoucher: null,
    voucherStatusList: stringUtils.VoucherStatusList,
    forConasi: FOR_CONASI_TARGET,
    voucherHeadersDynamic: [],
    enableDisableVoucherTitle: '',
    voucherSelectId: null,
    enableDisableVoucherValue: null,
    VoucherStatusType: VoucherStatusType,
    VoucherPrintType: VoucherPrintType,
    VoucherTransactionValue: VoucherTransactionValue
  }),
  computed: {
    ...mapGetters([
      'VOUCHERS_DATA',
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    GET_CURRENT_ACCOUNT: function () {
      // this.getEVoucherList()
    },
    VOUCHERS_DATA () {
      let res = this.VOUCHERS_DATA
      // Handle paginate
      this.voucherPaginate.currentPage = res.paginate.currentPage
      this.voucherPaginate.totalPage = res.paginate.totalPage
      this.voucherPaginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let voucherData = res.results
      this.voucherList = []
      for (let i = 0, size = voucherData.length; i < size; i++) {
        let voucherObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: voucherData[i].id,
          active: voucherData[i].active,
          status: voucherData[i].status,
          serial: voucherData[i].serial,
          workpieceCode: voucherData[i].workpiece.code,
          workpiece: voucherData[i].workpiece,
          voucherIssuanceCode: voucherData[i].workpiece && voucherData[i].workpiece.code,
          price: voucherData[i].workpiece && voucherData[i].workpiece.value,
          priceDiscount: this.getPriceDiscount(voucherData[i].discount_value, voucherData[i].workpiece),
          discountValue: voucherData[i].discount_value ? voucherData[i].discount_value : 0,
          selectedButton: false,
          isLoading: false,
          isDeleteLoading: false,
          fromEntity: voucherData[i].from_entity,
          toEntity: voucherData[i].to_entity,
          // Date updated
          dateUpdated: dateUtils.formatBeautyDate(voucherData[i].date_updated),
          // Valid date of customer
          expiredDate: dateUtils.formatBeautyDate(voucherData[i].expired_date, null),
          // Supplier name
          supplierName: voucherData[i].supplier_name,
          // Issue agent expired date of agent
          distributeEndDate: dateUtils.formatBeautyDate(voucherData[i].issuance.distribute_end_date),
          // Issue agent start date of agent
          // Issue start date of use of customer
          deliveredDate: dateUtils.formatBeautyDate(voucherData[i].delivered_date),
          pay: voucherData[i].is_prepay,
          customerName: functionUtils.concatFullname(voucherData[i].customer.last_name, voucherData[i].customer.first_name),
          deliveryFullName: voucherData[i].customer.delivery_fullname,
          deliveryNote: voucherData[i].delivery_note,
          emailCustomer: voucherData[i].customer.email,
          printedDate: dateUtils.formatBeautyDate(voucherData[i].printed_date),
          customerId: voucherData[i].customer.id,
          discountType: voucherData[i].discount_type,
          issuanceId: voucherData[i].issuance.id,
          issuanceCode: functionUtils.concatSuffixPrefix(voucherData[i].issuance),
          printTemplateId: voucherData[i].workpiece.printTemplateId,
          value: voucherData[i].workpiece && voucherData[i].workpiece.value
        }
        this.voucherList.push(voucherObj)
      }
      this.isLoading = false
    }
  },
  created () {
    this.voucherHeadersDynamic = this.voucherHeaders.filter(element => {
      if (this.isSupplier()) {
        return element.value !== 'supplierName' && element.value !== 'fromEntity'
      } else if (this.isUsageSite()) {
        return element.value !== 'fromEntity' && element.value !== 'toEntity' && element.value !== 'issuanceCode' && element.value !== 'workpieceCode'
      } else {
        return element.value !== 'issuanceCode' && element.value !== 'workpieceCode'
      }
    })
    // Add field print
    if (this.forConasi) {
      this.voucherHeadersDynamic.push({
        sortable: false,
        text: 'printVoucher.printed',
        value: 'printedDate',
        align: 'center'
      })
      this.voucherStatusList.push(
        {
          text: 'evoucher.status.distribution_expired',
          value: VoucherStatusType.DISTRIBUTION_EXPIRED
        },
        {
          text: 'evoucher.status.locked',
          value: VoucherStatusType.LOCKED_USING
        }
      )
    }
    if (!this.isSupplier()) {
      this.inputProps.style.width = '170px'
    }

    let routerQuery = this.$route.query
    // this.voucherPaginate.currentPage = functionUtils.getNumberValue(routerQuery && routerQuery.page, 1)
    this.searchData.voucherNameOrCode = functionUtils.getStringValue(routerQuery && routerQuery.code)
    this.searchData.voucherStatus = functionUtils.getNumberValue(routerQuery && routerQuery.type)
    this.searchData.issuanceSupplier = functionUtils.getStringValue(routerQuery && routerQuery.supplier)
    this.searchData.voucherPayStatus = functionUtils.getNumberValue(routerQuery && routerQuery.payStatus)
    this.searchData.customerInfo = functionUtils.getStringValue(routerQuery && routerQuery.info)
    this.range.start = functionUtils.getStringValue(routerQuery && routerQuery.fromDate)
    this.range.end = functionUtils.getStringValue(routerQuery && routerQuery.toDate)
    if (!functionUtils.isEmptyString(this.searchData.voucherNameOrCode) ||
        !functionUtils.isEmptyString(this.searchData.issuanceSupplier) ||
        !functionUtils.isEmptyString(this.searchData.customerInfo) ||
        !functionUtils.isEmptyString(this.range.start) ||
        !functionUtils.isEmptyString(this.range.end) ||
        !functionUtils.isNull(this.searchData.voucherStatus)
    ) {
      this.getEVoucherList()
    }
  },
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    getCustomerDisplayName: function (item) {
      let customerName = item.customerName
      if (!this.forConasi && !functionUtils.isEmptyString(item.deliveryFullName)) {
        customerName = item.deliveryFullName
      }
      return customerName
    },
    hasExportExcelPermission: function () {
      return functionUtils.roleInEntity(EntityRoleType.MANAGER)
    },
    isAccountant: function () {
      return functionUtils.roleInEntity(EntityRoleType.ACCOUNTANT)
    },
    /**
     * Go screen print voucher
     */
    onGoScreenPrintVoucher: function (type) {
      var voucherIds = []
      var params = null
      if (type === VoucherPrintType.SELECTED) {
        // eslint-disable-next-line eqeqeq
        if (this.selected.length == 0) {
          this.ON_SHOW_TOAST({
            message: this.$t('printVoucher.chooseVoucherToBePrinted'),
            styleType: ToastType.ERROR
          })
          return
        } else {
          let currentDate = moment().format(dateUtils.STATIC_FORMAT_DATE_SQL)
          for (let i = 0, size = this.selected.length; i < size; i++) {
            let expiredDate = dateUtils.formatCompareDate(this.selected[i].expiredDate)
            if ((this.selected[i].status !== VoucherStatusType.ACTIVATED && this.selected[i].status !== VoucherStatusType.WAITING) || functionUtils.isNull(this.selected[i].customerId)) {
              this.ON_SHOW_TOAST({
                message: this.$t('printVoucher.cannotPrintVoucherUnreleasedToCustomerOrUsed'),
                styleType: ToastType.ERROR
              })
              return
            } else if (currentDate > expiredDate) {
              this.ON_SHOW_TOAST({
                message: this.$t('printVoucher.cannotPrintVoucherExpiredDate'),
                styleType: ToastType.ERROR
              })
              return
            }
            voucherIds.push(this.selected[i].id)
          }
        }
      } else {
        let entityId = sessionStorage.getItem('entityId')
        let entityType = sessionStorage.getItem('entityType')
        params = {
          entityId: entityId, // TODO: remove?
          type: entityType, // TODO: remove?
          page: this.voucherPaginate.currentPage,
          supplierName: this.searchData.issuanceSupplier ? this.searchData.issuanceSupplier.trim() : null,
          startDate: this.range.start,
          endDate: this.range.end,
          voucherStatus: this.searchData.voucherStatus,
          voucherPayStatus: this.searchData.voucherPayStatus,
          voucherNameOrCode: this.searchData.voucherNameOrCode ? this.searchData.voucherNameOrCode.trim() : null
        }
      }
      let routeData = this.$router.resolve({ path: 'print-evoucher-v2', query: type === VoucherPrintType.SELECTED ? { voucherIds: JSON.stringify(voucherIds), type: type } : { params: JSON.stringify(params), type: type } })
      window.open(routeData.href, '_blank')
    },
    removeKey: function (object, key) {
      delete object[key]
    },
    renameKey: function (object, key, newKey) {
      const targetKey = object[key]
      delete object[key]
      object[newKey] = targetKey
      return object
    },
    onExportVoucherInfoToExcel: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let voucherIds = null
      if (this.selected.length > 0) {
        voucherIds = []
        for (let i = 0, size = this.selected.length; i < size; i++) {
          voucherIds.push(this.selected[i].id)
        }
      }
      let filter = {
        params: {
          startDate: this.range.start,
          endDate: this.range.end,
          voucherStatus: this.searchData.voucherStatus,
          voucherPayStatus: this.searchData.voucherPayStatus,
          voucherNameOrCode: this.searchData.voucherNameOrCode ? this.searchData.voucherNameOrCode.trim() : null,
          customerInfo: this.searchData.customerInfo,
          selectedVoucherIds: voucherIds
        }
      }

      this.isLoading = true
      this.GET_VOUCHER_FOR_EXPORTING(filter).then(
        function (res) {
          let vouchers = []
          let data = res.data
          for (let i = 0, size = data.length; i < size; i++) {
            let customerFullname = functionUtils.concatFullname(data[i].customer.last_name, data[i].customer.first_name)
            if (!this.forConasi && !functionUtils.isEmptyString(data[i].customer.delivery_fullname)) {
              customerFullname = data[i].customer.delivery_fullname
            }
            let voucherObj = {
              stt: i + 1,
              voucherName: data[i].workpiece.name,
              workpieceCode: data[i].workpiece.code,
              serialCode: data[i].serial,
              price: data[i].workpiece && data[i].workpiece.value,
              deliveredDate: dateUtils.formatBeautyDate(data[i].delivered_date),
              expiredDate: dateUtils.formatBeautyDate(data[i].expired_date, null),
              customerEmail: data[i].customer.email,
              customerName: customerFullname,
              customerPhone: data[i].customer.phone,
              status: this.$t(functionUtils.getVoucherStatusText(data[i].status)),
              deliveryNote: data[i].delivery_note
            }
            vouchers.push(voucherObj)
          }

          for (let i = 0, size = vouchers.length; i < size; i++) {
            for (var property in vouchers[i]) {
              if (property === 'stt') {
                this.renameKey(vouchers[i], property, this.$t('common.nonumber'))
              } else if (property === 'voucherName') {
                this.renameKey(vouchers[i], property, this.$t('evoucher.voucherName'))
              } else if (property === 'serialCode') {
                this.renameKey(vouchers[i], property, this.$t('evoucher.voucherSerial'))
              } else if (property === 'workpieceCode') {
                this.renameKey(vouchers[i], property, this.$t('evoucher.issuedCode'))
              } else if (property === 'price') {
                this.renameKey(vouchers[i], property, this.$t('infoVoucher.unitPrice'))
              } else if (property === 'deliveredDate') {
                this.renameKey(vouchers[i], property, this.$t('evoucher.dateDistributed'))
              } else if (property === 'expiredDate') {
                this.renameKey(vouchers[i], property, this.$t('createVoucher.expiredDate'))
              } else if (property === 'customerEmail') {
                this.renameKey(vouchers[i], property, this.$t('customer.emailCustomer'))
              } else if (property === 'customerName') {
                this.renameKey(vouchers[i], property, this.$t('customer.nameCustomer'))
              } else if (property === 'customerPhone') {
                this.renameKey(vouchers[i], property, this.$t('customer.phone'))
              } else if (property === 'deliveryNote') {
                this.renameKey(vouchers[i], property, this.$t('evoucher.deliveryNote'))
              } else if (property === 'status') {
                this.renameKey(vouchers[i], property, this.$t('common.state'))
              }
            }
          }
          // export json to Worksheet of Excel only array possible
          var voucherExport = XLSX.utils.json_to_sheet(vouchers)
          // A workbook is the name given to an Excel file
          var wb = XLSX.utils.book_new() // make Workbook of Excel
          // add Worksheet to Workbook, Workbook contains one or more worksheets
          let entityName = sessionStorage.getItem('entityName')
          XLSX.utils.book_append_sheet(wb, voucherExport, `${this.$t('sideBar.evoucherList')}`) // sheetAName is name of Worksheet
          // export Excel file
          XLSX.writeFile(wb, `${this.$t('sideBar.evoucherList')} - ${entityName}` + '.xlsx') // name of the file is 'book.xlsx'
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Get pay status text
     */
    getPayStatusText: function (isPrepay) {
      if (isPrepay) {
        return this.$t('createVoucher.prepaid')
      }
      return this.$t('createVoucher.unpaid')
    },
    /**
    * Confirm delete voucher
    */
    onConfirmDeleteVoucher: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.itemVoucher.isDeleteLoading = true
      this.DELETE_VOUCHER({ id: this.itemVoucher.id }).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getEVoucherList()
          this.itemVoucher.isDeleteLoading = false
          this.itemVoucher = null
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
          this.itemVoucher.isDeleteLoading = false
          this.itemVoucher = null
        }.bind(this)
      )
    },
    /**
     * Delete voucher
     */
    onDeleteVoucher: function (event, voucher) {
      event.preventDefault()
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.$refs.confirmDeleteVoucherModal.onShowModal()
      this.itemVoucher = voucher
    },
    /**
     * Check user has is agent
     */
    isAgent: function () {
      return functionUtils.isEntityType(EntityType.AGENT)
    },
    /**
     * Show modal deliver voucher by excel mode
     */
    onShowModalDeliveryVoucherByExcelMode: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.$refs.assignVoucherByExcelModeModal.onShowModal()
    },
    /**
     * Confirm used direct voucher
     */
    onConfirmUsedDirect: function (activeCode, usedPrice, discountPrice, usedInfo) {
      let filter = {
        voucherId: this.itemVoucher.id,
        status: this.itemId === 1 ? VoucherStatusType.USED : VoucherStatusType.ACTIVATED,
        fromEntityType: EntityType.SUPPLIER,
        activeCode: activeCode,
        usedPrice: usedPrice,
        discountPrice: discountPrice,
        usedInfo: usedInfo
      }
      this.CONFIRM_VOUCHER_ASSIGNED(filter)
        .then(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.$refs.confirmUsedVoucherDirectModal.onCloseModal()
            this.getEVoucherList()
          }.bind(this)
        )
        .catch(
          function (error) {
            this.itemVoucher.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
            this.$refs.confirmUsedVoucherDirectModal.onConfirmFailed()
          }.bind(this)
        )
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * View detail entity
     */
    viewDetailEntity: function (event, entity) {
      event.preventDefault()
      this.$refs.entityDetailModal.onShowModal(entity.id)
    },
    /**
     * Check is supplier
     */
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    /**
     * Request e voucher to parent
     */
    requestEVoucherToParent: function (event) {
      event.preventDefault()
      this.$refs.requestEVoucherToParent.onShowModal()
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    /**
     * Get price discount
     */
    getPriceDiscount: function (discount, workpiece) {
      let price = 0
      if (!functionUtils.isNull(workpiece.value)) {
        price = workpiece.value
      }
      return price * (discount / 100)
    },
    showPreviewVoucher: function (event, voucherId) {
      event.preventDefault()
      this.$refs.voucherPreviewModal.onShowModal(voucherId)
    },
    /**
     * Confirm used voucher
     */
    confirmUsedVoucherFromCustomer: function () {
      event.preventDefault()
      this.itemVoucher.isLoading = true
      let filter = {
        voucherId: this.itemVoucher.id,
        fromEntityType: EntityType.SUPPLIER
      }
      this.REQUEST_CONFIRM_VOUCHER_USED_FROM_CUSTOMER(filter)
        .then(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.getEVoucherList()
          }.bind(this)
        )
        .catch(
          function (error) {
            this.itemVoucher.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    /**
     * View history transaction
     */
    viewHistoryTransaction: function (event, voucherId) {
      event.preventDefault()
      this.$refs.historyTransactionVoucher.onShowModal(voucherId)
    },
    /**
     * Search
     */
    onSearch: function () {
      if (!functionUtils.isEmptyString(this.searchData.voucherNameOrCode) ||
        !functionUtils.isEmptyString(this.searchData.issuanceSupplier) ||
        !functionUtils.isEmptyString(this.searchData.customerInfo) ||
        !functionUtils.isEmptyString(this.range.start) ||
        !functionUtils.isEmptyString(this.range.end) ||
        !functionUtils.isNull(this.searchData.voucherStatus)
      ) {
        this.$router.replace({
          query: {
            code: functionUtils.getStringValue(this.searchData.voucherNameOrCode),
            type: this.searchData.voucherStatus,
            supplier: functionUtils.getStringValue(this.searchData.issuanceSupplier),
            payStatus: this.searchData.voucherPayStatus,
            info: functionUtils.getStringValue(this.searchData.customerInfo),
            fromDate: functionUtils.getStringValue(this.range.start),
            toDate: functionUtils.getStringValue(this.range.end)
            // page: this.voucherPaginate.currentPage
          }
        })
        this.getEVoucherList()
      }
    },
    /**
     * Transaction voucher success
     */
    onTransactionVoucherSuccess: function () {
      this.getEVoucherList()
    },
    /**
     * Create e voucher success
     */
    onCreateEVoucherSuccess: function () {
      this.getEVoucherList()
    },
    onExtendExpiredDateSuccess: function () {
      this.getEVoucherList()
    },
    /**
     * Check has action
     */
    hasAction: function (metaAction) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaAction)
    },
    /**
     * Show modal add list e voucher
     */
    onShowModalAddListEvoucher: function (createVoucherType, workpieceId, issuanceId) {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.$refs.addEVoucherList.onShowModal(createVoucherType, workpieceId, issuanceId)
    },
    /**
     * Show modal assign voucher
     */
    onShowModalAssignVoucher: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      for (let i = 0, sizeA = this.selected.length; i < sizeA; i++) {
        let distributeEndDate = dateUtils.formatCompareDate(this.selected[i].distributeEndDate, null)
        let currentDate = moment().format(dateUtils.STATIC_FORMAT_DATE_SQL)
        if (this.selected[0].workpiece.id !== this.selected[i].workpiece.id) {
          this.ON_SHOW_TOAST({
            'message': this.$t('evoucher.voucherFromDifferentIssuesCannotBeIssued'),
            'styleType': ToastType.ERROR
          })
          return
        } else if (this.selected[0].workpiece.owner !== this.selected[i].workpiece.owner) {
          this.ON_SHOW_TOAST({
            'message': this.$t('evoucher.voucherFromDifferentIssuanceCannotBeIssued'),
            'styleType': ToastType.ERROR
          })
          return
        } else if (this.selected[0].workpiece.type !== this.selected[i].workpiece.type) {
          this.ON_SHOW_TOAST({
            'message': this.$t('evoucher.voucherWithDifferentDiscountType'),
            'styleType': ToastType.ERROR
          })
          return
        } else if (this.selected[0].issuanceId !== this.selected[i].issuanceId) {
          this.ON_SHOW_TOAST({
            'message': this.$t('evoucher.voucherFromDifferentPrefixSuffixCannotBeIssued'),
            'styleType': ToastType.ERROR
          })
          return
        } else if (this.selected[0].price !== this.selected[i].price || this.selected[0].discountValue !== this.selected[i].discountValue || this.selected[0].discountType !== this.selected[i].discountType) {
          this.ON_SHOW_TOAST({
            'message': this.$t('evoucher.voucherWithDifferentDenominations'),
            'styleType': ToastType.ERROR
          })
          return
        } else if (currentDate > distributeEndDate) {
          this.ON_SHOW_TOAST({
            'message': this.$t('evoucher.voucherIssueExpired'),
            'styleType': ToastType.ERROR
          })
          return
        } else if ((this.selected[0].status !== this.VoucherStatusType.NOT_ASSIGNED_YET) && (this.selected[0].status !== this.VoucherStatusType.INACTIVE)) {
          this.ON_SHOW_TOAST({
            'message': this.$t('evoucher.canNotDistributedVoucher', { '0': this.selected[0].serial }),
            'styleType': ToastType.ERROR
          })
          return
        }
      }
      this.$refs.assignVoucherModal.onShowModal(this.selected)
    },
    onBulkExternMultipleVouchers: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      var voucherIds = []
      if (this.selected.length === 0) {
        this.ON_SHOW_TOAST({
          message: this.$t('printVoucher.chooseVoucherToBePrinted'),
          styleType: ToastType.ERROR
        })
      } else {
        for (let i = 0, size = this.selected.length; i < size; i++) {
          if (this.selected[i].status !== VoucherStatusType.ACTIVATED || functionUtils.isNull(this.selected[i].customerId)) {
            this.ON_SHOW_TOAST({
              message: this.$t('Cannot extern unreleased to customer vouchers'),
              styleType: ToastType.ERROR
            })
            return
          }
          voucherIds.push(this.selected[i].id)
        }
        this.$refs.extendExpiredDayModal.onShowModal(voucherIds, null, null)
      }
    },
    onShowModalExternExpiredDays: function (event, itemVoucher) {
      event.preventDefault()
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      this.$refs.extendExpiredDayModal.onShowModal([itemVoucher.id], null, null)
    },
    isVoucherExpired: function (expiredDate) {
      expiredDate = dateUtils.formatCompareDate(expiredDate)
      let currentDate = moment().format(dateUtils.STATIC_FORMAT_DATE_SQL)
      return (currentDate > expiredDate)
    },
    onShowModalConfirmVoucher: function (event, itemVoucher, itemId) {
      event.preventDefault()
      this.itemId = itemId
      this.itemVoucher = itemVoucher
      if (this.isVoucherExpired(this.itemVoucher.expiredDate)) {
        this.itemVoucher.isLoading = false
        this.ON_SHOW_TOAST({
          message: this.$t('evoucher.voucherHasExpired'),
          styleType: ToastType.ERROR
        })
      } else if (itemId === 1) {
        this.$refs.confirmUsedVoucherDirectModal.onShowModal(itemVoucher)
      } else {
        this.confirmModalTitle = this.$t('evoucher.issuance.doYouWantToConfirm')
        this.$refs.confirmModal.onShowModal()
      }
    },
    onShowConfirmUsedMultipleVouchersModal: function () {
      this.$refs.confirmUsedMultipleVouchersModal.onShowModal()
    },
    onShowConfirmUnlockUsingVouchersModal: function () {
      this.$refs.confirmUnlockUsingVouchersModal.onShowModal()
    },
    /**
     * Get e voucher list
     */
    getEVoucherList: function () {
      let filter = {
        params: {
          page: this.voucherPaginate.currentPage,
          supplierName: this.searchData.issuanceSupplier ? this.searchData.issuanceSupplier.trim() : null,
          startDate: this.range.start,
          endDate: this.range.end,
          voucherStatus: this.searchData.voucherStatus,
          voucherPayStatus: this.searchData.voucherPayStatus,
          voucherNameOrCode: this.searchData.voucherNameOrCode ? this.searchData.voucherNameOrCode.trim() : null,
          customerInfo: this.searchData.customerInfo
        }
      }
      this.isLoading = true
      this.GET_VOUCHER_LIST(filter)
        .then(function () {})
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    /**
     * Get e voucher status type
     */
    getVoucherStatusType: function (status) {
      return functionUtils.getVoucherStatusText(status)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.voucherPaginate.currentPage = page
      this.getEVoucherList()
    },
    /**
     * Check is site
     */
    isUsageSite: function () {
      return functionUtils.isEntityType(EntityType.SITE)
    },
    onShowModalAddNewVoucher: function () {
      this.$refs.addIssuanceAndVoucher.onShowModal(null)
    },
    enableDisableVoucher: function (event, eVoucherInfo, value) {
      event.preventDefault()
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let actionName = value === this.VoucherStatusType.ACTIVATED ? this.$t('evoucher.enableVoucher') : this.$t('evoucher.disableVoucher')
      this.enableDisableVoucherTitle = this.$t('evoucher.notiEnableDisable', { 'action': actionName, 'voucher': eVoucherInfo.workpiece.name })
      this.voucherSelectId = eVoucherInfo.id
      this.enableDisableVoucherValue = value
      this.$refs.enableDisableVoucherModal.onShowModal()
    },
    confirmEnableDisableVoucher: function () {
      let data = {
        voucherId: this.voucherSelectId,
        voucherStatus: this.enableDisableVoucherValue
      }
      this.isLoading = true
      this.ENABLE_OR_DISABLE_VOUCHER(data).then(
        function () {
          this.getEVoucherList()
          this.isLoading = false
        }
      ).catch(
        function () {
          this.getEVoucherList()
          this.isLoading = false
        }.bind(this)
      )
    },
    onShowModalTransferVoucherToOtherCustomer: function (event, itemVoucher) {
      event.preventDefault()
      this.$refs.transferVoucherToOtherCustomerModal.onShowModal(itemVoucher)
    },
    confirmTransferVoucherToOtherCustomer: function (data) {
      this.getEVoucherList()
    },
    getVoucherStatusColorName: function (voucher) {
      if (voucher.status === VoucherStatusType.USED) return 'accepted'
      if (voucher.status === VoucherStatusType.DISABLED) return 'unsubscribed'
      if (voucher.status === VoucherStatusType.LOCKED_USING) return 'warning'
      if (
        voucher.status === VoucherStatusType.USING_EXPIRED ||
        voucher.status === VoucherStatusType.DISTRIBUTION_EXPIRED ||
        (voucher.status === VoucherStatusType.ACTIVATED && this.isVoucherExpired(voucher.expiredDate))
      ) return 'failed'
      if (voucher.status === VoucherStatusType.ACTIVATED) return 'primary'
      return 'dafault'
    },
    ...mapActions([
      'GET_VOUCHER_LIST',
      'GET_VOUCHER_FOR_EXPORTING',
      'CONFIRM_VOUCHER_ASSIGNED',
      'ON_SHOW_TOAST',
      'REQUEST_CONFIRM_VOUCHER_USED_FROM_CUSTOMER',
      'DELETE_VOUCHER',
      'ENABLE_OR_DISABLE_VOUCHER'
    ])
  }
}
</script>
<style lang="scss" scoped>
.bg-white {
  background-color: #fff !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
/deep/.theme--light.v-table tbody tr {
  position: relative;
}
.bg-1 {
  animation: myBg 5s infinite;
}
.action-item {
  cursor: pointer;
  border-bottom: 1px dashed #5cb860;
}
@keyframes myBg {
  from {background-color: #E8F5E9;}
  to {background-color: #4CAF50;}
}
@media (min-width: 960px) {
  .flex.lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
/deep/.v-menu--inline {
  display: block;
}
</style>
