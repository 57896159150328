<template>
  <v-dialog
    v-model="isShowModal"
    max-width="1200px"
    persistent>
    <v-card>
      <material-card
        :title="$t('evoucher.manageLockedVouchers')"
        tile
        color="green"
        full-width
      >
        <v-container
          grid-list-xl
          fluid
          style="padding: 0;">
          <v-form
            ref="form"
            lazy-validation>
            <v-layout
              layout
              wrap>
              <v-flex
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="orderCode"
                  :label="$t('evoucher.referenceCode')"
                  :name="$t('evoucher.referenceCode')"
                  type="text"
                  @keyup.enter="onSearch"
                />
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="serialNumber"
                  :label="$t('evoucher.voucherSerial')"
                  :name="$t('evoucher.voucherSerial')"
                  type="text"
                  @keyup.enter="onSearch"
                />
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="customerInfo"
                  :label="$t('evoucher.customerUsed')"
                  :name="$t('evoucher.customerUsed')"
                  type="text"
                  @keyup.enter="onSearch"
                />
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-menu
                  ref="menu"
                  v-model="menuUsedDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="usingDateFormatted"
                      :label="$t('report.supplier.dateOfUse')"
                      persistent-hint
                      @blur="date = parseDate(usingDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="usingDate"
                    no-title
                    @input="menuUsedDate = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-menu
                  ref="menu"
                  v-model="menuStayDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="stayDateFormatted"
                      :label="$t('evoucher.stayDate')"
                      persistent-hint
                      @blur="date = parseDate(stayDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="stayDate"
                    no-title
                    @input="menuStayDate = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-btn
                  style="margin-right: 0;"
                  class="pull-right"
                  color="success"
                  @click="onSearch"
                  @keyup.enter="onSearch"
                >
                  <span class="btn-label mr-3">
                    <i class="glyphicon glyphicon-search" />
                  </span>
                  <span style="text-transform: none;">{{
                    $t("common.search")
                  }}</span>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout
              layout
              wrap>
              <v-flex
                xs12
                sm12
                md12>
                <div @mouseleave="$refs.tblVoucherList.expanded=[]">
                  <v-data-table
                    ref="tblVoucherList"
                    :no-data-text="$t('common.noDataAvailable')"
                    :headers="eUsingVoucherHeaders"
                    :items="eUsingVoucherList"
                    :expand="false"
                    :single-select="true"
                    v-model="voucherSelected"
                    hide-actions
                    select-all
                  >
                    <template
                      slot="headerCell"
                      slot-scope="{ header }">
                      <span
                        v-if="header.value"
                        class="text--darken-3 font-medium"
                        v-text="$t(header.text)"
                      />
                    </template>
                    <template v-slot:items="props">
                      <tr
                        :class="props.selected ? 'blue--text' : ''"
                        style="position: relative;"
                        @click="props.expanded=!props.expanded"
                        @mouseover="props.expanded=true">
                        <td>
                          <v-checkbox
                            v-model="props.selected"
                            primary
                            hide-details />
                        </td>
                        <td
                          v-for="(header, index) in eUsingVoucherHeaders"
                          :key="header.value + index">
                          <div
                            v-if="header.value == 'orderCode'"
                            class="text-xs-center">
                            <span
                              class="action-item"
                              @click="editReferenceInfo(props.item.referenceInfo)">{{ props.item.referenceInfo.orderCode }}</span>
                          </div>
                          <div
                            v-else-if="header.value == 'price'"
                            class="text-xs-center">
                            {{ formatMoney(props.item[header.value]) }} VNĐ
                          </div>
                          <div
                            v-else-if="header.value == 'customerInfo'">
                            <span
                              v-if="props.item.referenceInfo.customerName"
                              class="action-item"
                              @click="editReferenceInfo(props.item.referenceInfo)">
                              <b>{{ props.item.referenceInfo.customerName }}</b><br>
                            </span>{{ formatCustomerInfo(props.item.referenceInfo) }}
                          </div>
                          <!-- <div
                            v-else-if="header.value == 'actions'"
                            class="text-xs-center">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  class="mx-2"
                                  fab
                                  small
                                  color="red"
                                  style="color: #ffffff;"
                                  v-on="on"
                                  @click="onRemoveUsingVoucher(props.item.id)">
                                  <v-icon dark>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('common.delete') }}</span>
                            </v-tooltip>
                          </div> -->
                          <div
                            v-else
                            class="text-xs-center">{{ props.item[header.value] }}</div>
                        </td>
                      </tr>
                    </template>
                    <!-- <template v-slot:expand="props">
                      <div
                        class="py-2 mx-3"
                        style="padding-left: 70px"
                        @mouseleave="props.expanded=false">
                        <div style="display: flex; white-space: nowrap;">
                          <action-item
                            v-if="hasAction('view_voucher') || isUsageSite()"
                            first
                            class="text-success"
                            @click="showPreviewVoucher($event, props.item.id)">
                            {{ $t('infoVoucher.voucherInformation') }}
                          </action-item>
                        </div>
                      </div>
                    </template> -->
                  </v-data-table>
                </div>
              </v-flex>
            </v-layout>
            <pagination
              :total="voucherPaginate.totalPage"
              :current-page="voucherPaginate.currentPage"
              :row-per-page="voucherPaginate.rowPerPage"
              @onPageChange="onPageChange"
            />
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-btn
          small
          color="error darken-1"
          @click="isShowModal = false">
          {{ $t("common.close") }}
        </v-btn>
        <v-spacer />
        <v-btn
          :loading="isLoading"
          :disabled="voucherSelected.length==0"
          small
          color="warning darken-1"
          @click="onConfirm($event, true)">
          {{ $t("voucherUsed.refundVoucher") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          :disabled="voucherSelected.length==0"
          small
          color="success darken-1"
          @click="onConfirm($event, false)">
          {{ $t("voucherUsed.usedVoucher") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onProcessLockedUsingVouchers"
    />
    <edit-reference-info-modal
      ref="editRefenceInfoModal"
      @onConfirm="onConfirmUpdateReferenceInfo"/>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import { FOR_CONASI_TARGET } from 'utils/constants'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import VoucherValue from 'enum/voucherValue'
import ConfirmModal from 'Components/ConfirmModal'
import EditReferenceInfoModal from './EditReferenceInfoModal.vue'
export default {
  components: {
    ConfirmModal,
    EditReferenceInfoModal
  },
  data () {
    return {
      isShowModal: false,
      isLoading: false,
      confirmModalTitle: '',
      VoucherValue: VoucherValue,
      refundVouchers: false,
      orderCode: null,
      serialNumber: null,
      customerInfo: null,
      usingDate: null,
      usingDateFormatted: null,
      stayDate: null,
      stayDateFormatted: null,
      menuUsedDate: false,
      menuStayDate: false,
      voucherSelected: [],
      eUsingVoucherList: [],
      eUsingVoucherHeaders: [
        {
          sortable: false,
          text: 'common.nonumber',
          value: 'stt',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.referenceCode',
          value: 'orderCode',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.customerUsed',
          value: 'customerInfo',
          align: 'center'
        },
        // {
        //   sortable: false,
        //   text: 'customer.emailCustomer',
        //   value: 'customerEmail',
        //   align: 'center'
        // },
        // {
        //   sortable: false,
        //   text: 'customer.phone',
        //   value: 'customerPhone',
        //   align: 'center'
        // },
        {
          sortable: false,
          text: 'evoucher.voucherSerial',
          value: 'voucherSerial',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.voucherName',
          value: 'voucherName',
          align: 'center'
        },
        // {
        //   sortable: false,
        //   text: 'evoucher.issuance.supplier',
        //   value: 'supplierName',
        //   align: 'center'
        // },
        {
          sortable: false,
          text: 'evoucher.issuance.price',
          value: 'price',
          align: 'center'
        },
        {
          sortable: false,
          text: 'report.supplier.dateOfUse',
          value: 'usedDate',
          align: 'center'
        },
        {
          sortable: false,
          text: 'createVoucher.expiredDate',
          value: 'expiredDate',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.arrivalDate',
          value: 'arrivalDate',
          align: 'center'
        },
        {
          sortable: false,
          text: 'evoucher.departureDate',
          value: 'departureDate',
          align: 'center'
        }
        // {
        //   sortable: false,
        //   text: 'common.actions',
        //   value: 'actions',
        //   align: 'center'
        // }
      ],
      voucherPaginate: {
        totalPage: 1,
        currentPage: 1,
        rowPerPage: 1
      },
      forConasi: FOR_CONASI_TARGET
    }
  },
  computed: {
    ...mapGetters([
      'VOUCHERS_LOCKED_USING_DATA'
    ])
  },
  watch: {
    VOUCHERS_LOCKED_USING_DATA () {
      let res = this.VOUCHERS_LOCKED_USING_DATA
      // Handle paginate
      this.voucherPaginate.currentPage = res.paginate.currentPage
      this.voucherPaginate.totalPage = res.paginate.totalPage
      this.voucherPaginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let voucherData = res.results
      this.eUsingVoucherList = []
      this.voucherSelected = []
      for (let i = 0, size = voucherData.length; i < size; i++) {
        let voucherObj = {
          'stt': i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          'id': voucherData[i].voucher_id,
          'referenceInfo': {
            'id': voucherData[i].reference_info.id,
            'orderCode': voucherData[i].reference_info.orderCode,
            'customerName': voucherData[i].reference_info.customer_name,
            'customerEmail': voucherData[i].reference_info.customer_email,
            'customerPhone': voucherData[i].reference_info.customer_phone,
            'startDate': voucherData[i].reference_info.start_date,
            'endDate': voucherData[i].reference_info.end_date,
            'note': voucherData[i].reference_info.note
          },
          'usedDate': dateUtils.formatBeautyDateTime(voucherData[i].used_date),
          'arrivalDate': dateUtils.formatBeautyDate(voucherData[i].reference_info.start_date),
          'departureDate': dateUtils.formatBeautyDate(voucherData[i].reference_info.end_date),
          'price': (voucherData[i].price && voucherData[i].price > 0) ? voucherData[i].price : voucherData[i].usedPrice,
          'voucherSerial': voucherData[i].voucher.serial,
          'voucherName': voucherData[i].voucher.name,
          'expiredDate': dateUtils.formatBeautyDate(voucherData[i].voucher.expired_date)
        }
        this.eUsingVoucherList.push(voucherObj)
      }
      this.isLoading = false
    },
    usingDate (val) {
      this.usingDateFormatted = this.formatDate(this.usingDate)
    },
    stayDate (val) {
      this.stayDateFormatted = this.formatDate(this.stayDate)
    }
  },
  methods: {
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatCustomerInfo: function (item) {
      if (!functionUtils.isEmptyString(item.customerEmail) || !functionUtils.isEmptyString(item.customerPhone)) {
        var extra = null
        if (functionUtils.isEmptyString(item.customerEmail) || functionUtils.isEmptyString(item.customerPhone)) {
          extra = item.customerEmail ? item.customerEmail : '' + item.customerPhone ? item.customerPhone : ''
        } else {
          extra = item.customerEmail + ' - ' + item.customerPhone
        }
        return '(' + extra + ')'
      }
      return ''
    },
    getLockedUsingVouchersList: function () {
      let filter = {
        params: {
          page: this.voucherPaginate.currentPage,
          customer: this.customerInfo,
          orderCode: this.orderCode,
          serialNumber: this.serialNumber,
          usedDate: this.usingDate,
          stayDate: this.stayDate
        }
      }
      this.isLoading = true
      this.GET_VOUCHERS_LOCKED_USING(filter)
        .then(function () {})
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    onPageChange: function (page) {
      this.voucherPaginate.currentPage = page
      this.getLockedUsingVouchersList()
    },
    onSearch: function () {
      this.getLockedUsingVouchersList()
    },
    // onRemoveUsingVoucher: function (id) {
    //   this.eUsingVoucherList = this.eUsingVoucherList.filter(element => {
    //     return element.id !== id
    //   })
    // },
    editReferenceInfo: function (refItem) {
      this.$refs.editRefenceInfoModal.onShowModal(refItem)
    },
    onConfirmUpdateReferenceInfo: function () {
      this.getLockedUsingVouchersList()
    },
    /**
     * Confirm
     */
    onConfirm: function (event, isRefundMode) {
      event.preventDefault()
      if (this.$refs.form.validate()) {
        this.confirmModalTitle = isRefundMode ? this.$t('evoucher.confirmRefundLockedVouchersMessage') : this.$t('evoucher.confirmUsedLockedVouchersMessage')
        this.refundVouchers = isRefundMode
        this.$refs.confirmModal.onShowModal()
      }
    },
    onProcessLockedUsingVouchers: function () {
      this.isLoading = true
      var voucherIds = []
      for (let i = 0, size = this.voucherSelected.length; i < size; i++) {
        voucherIds.push(this.voucherSelected[i].id)
      }
      let filter = {
        voucherIds: voucherIds,
        isRefundVouchers: this.refundVouchers ? 1 : 0
      }
      this.PROCESS_VOUCHERS_LOCKED_USING(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.isLoading = false
          this.isShowModal = false
        }.bind(this)
      ).catch(
        function (error) {
          this.isLoading = false
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    /**
     * Show modal
     */
    onShowModal: function () {
      this.voucherSelected = []
      this.eUsingVoucherList = []
      this.orderCode = null
      this.customerInfo = null
      this.serialNumber = null
      this.isShowModal = true
      this.getLockedUsingVouchersList()
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_VOUCHERS_LOCKED_USING',
      'PROCESS_VOUCHERS_LOCKED_USING'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.action-item {
  cursor: pointer;
  border-bottom: 1px dashed #5cb860;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
